<div class="flex flex-col justify-center items-center gap-[24px]"
     [ngStyle]="{ 'width': config.width ? config.width: '480px' }">
  <!--Card icon-->
  <div [ngClass]="cssClass()">
    <span [inlineSVG]="'assets/icons/' + iconName() + '.svg'"></span>
  </div>
  <!--Card icon-->

  <!--Card title and description-->
  <div class="flex flex-col gap-[12px] text-center">
    <p class="font-medium text-neutral-900"
       [ngClass]="titleFontSize()">
      {{config.title.text}}
    </p>
    <p class="font-normal text-neutral-700"
       [ngClass]="descriptionFontSize()"
       [innerHTML]="config.description.text">
    </p>
  </div>
  <!--Card title and description-->

  <!--Card action-->
  <ng-content select="[card-action]"></ng-content>
  <!--Card action-->
</div>
