import {Component, Input} from '@angular/core';
import {InlineSVGModule} from "ng-inline-svg-2";
import {UtilsService} from "../../services/global/utils-service.service";
import {InfoCardConfigModel} from "../../models/global/info-card-config.model";
import {NgClass, NgStyle} from "@angular/common";

@Component({
  selector: 'crm-info-card',
  standalone: true,
  imports: [
    InlineSVGModule,
    NgClass,
    NgStyle
  ],
  templateUrl: './info-card.component.html',
  styleUrl: './info-card.component.scss'
})
export class InfoCardComponent {
  @Input({required: true}) config: InfoCardConfigModel;

  constructor(private utilsService: UtilsService) {}

  /**
   * Returns the CSS class for the modal icon based on the given model type
   */
  cssClass() {
    return this.utilsService.cssClass(this.config.type);
  }

  /**
   * Returns icon name to use in the modal on the given model type.
   */
  iconName() {
    if (this.config.iconName) {
      return this.config.iconName;
    }
    return this.utilsService.modalIconName(this.config.type);
  }

  titleFontSize() {
    return `text-[${this.config.title.fontSize ? this.config.title.fontSize: '29px'}]`
  }

  descriptionFontSize() {
    return `text-[${this.config.description.fontSize ? this.config.description.fontSize: '14px'}]`
  }
}
